<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="card">
      <div v-if="loadPoster()" class="card_logo">
        <img
          :src="loadPoster()"
          class="logo_oridinary"
          :class="[isEtisalatUAE ? 'logo_etisalat' : '']"
          alt="tlp-logo"
        />
      </div>

      <div v-if="!isOTPActive">
        <div class="card_welcomeText">
          <p class="card_welcomeText_primary_text">
            {{ $t("welcome to noorplay") }}
          </p>
          <!-- Only for Etisalat -->
          <p v-if="isEtisalatUAE" class="card_welcomeText_secondary_text">
            {{
              $t(
                `Free for ${plan.trialperioddays} days then AED ${
                  plan.amount / 100
                } / ${
                  plan.planinterval === "WEEK" ? "Week" : "Month"
                }, VAT Inclusive`
              )
            }}
          </p>
          <!-- For Other PGs -->
          <p v-else-if="plan && plan.trialperioddays" class="card_welcomeText_secondary_text">
            {{ $t("Try") }} {{ plan.trialperioddays }}
            {{ $t("days for free. Cancel anytime") }}
          </p>

          <p v-if="isTpay" class="card_welcomeText_secondary_text">
            {{ $t(`Internet usage is deducted from your internet bundle`) }}
          </p>
        </div>

        <div class="card_inputs">
          <div>
            <div
              class="card_inputs_cards"
              :class="selectedIndex === index ? 'active_input' : ''"
              v-for="(item, index) in plans"
              :key="index"
              @click="planSelect(item, index)"
            >
              <div
                :class="
                  localDisplayLang === 'ara'
                    ? 'radio_container_right'
                    : 'radio_container'
                "
              >
                <label class="radio_label" :for="`${item.planinterval}${index}`"
                  >{{ $t(`${item.planinterval}`) }}
                  <input
                    type="radio"
                    :id="`${item.planinterval}${index}`"
                    :value="item"
                    v-model="plan"
                  />
                  <span
                    :class="
                      localDisplayLang === 'ara'
                        ? 'radiomark_check_right'
                        : 'radiomark_check'
                    "
                  ></span>
                </label>
              </div>
              <p v-if="item.currency == `ILS`" class="card_inputs_cards_pricetag">
                {{ `${item.displaycurrency}` + " " + calculatePrice(item) }}
              </p>
							<p v-else class="card_inputs_cards_pricetag">
                {{ `${item.currency}` + " " + calculatePrice(item) }}
              </p>
            </div>
          </div>
        </div>

        <div class="card_parallel_text">
          <p class="card_phone_numebr_text">{{ $t("Phone Number") }}</p>
          <p class="card_auto_renewal_text">{{ $t("Auto Renewal") }}</p>
        </div>

        <div dir="ltr" class="card_mobile_input_body">
          <div v-if="country_code" class="card_input_country_code">
            {{ country_code }}
          </div>
          <input
            type="tel"
            @beforeinput="specialCharValidationMobile($event)"
            class="mobile-number"
            :class="[
              localDisplayLang == 'eng'
                ? 'card_input_text'
                : 'card_input_text_right',
            ]"
            v-model="mobile_number"
            onkeypress="return this.value.length > 9 ? false :true"
          />
        </div>
        <div class="card_button">
          <button @click="startFreeTrail()" v-if="!btnLoader">
            {{ $t("Subscribe") }}
          </button>
          <Loading v-else></Loading>

          <!-- <div class="card_info">
            <p v-if="isEtisalatUAE" class="primary">
              <span class="click" @click="exitPage()">{{ $t("Exit the page") }}</span>
            </p>
          </div> -->
        </div>
        <div class="card_info">
          <p v-if="notCustomer" class="primary">
            {{ notCustomer }}
            <span class="click" @click="redirectToSubscriptionPage()">{{
              $t("Click here")
            }}</span>
          </p>
          <p class="secondary" v-html="planTermsText(plan)"></p>

          <br />

          <p v-if="isEtisalatUAE" class="primary">
            {{ $t("For complete T’s &C’s") }}
            <span class="click" @click="redirectTermsAndConditions()">{{
              $t("Click here")
            }}</span>
          </p>
        </div>
      </div>

      <div v-else>
        <div v-if="verifyNumber">
          <div class="card_welcomeText">
            <p class="card_welcomeText_primary_text">
              {{ $t("Verify Your Number") }}
            </p>
            <p v-if="isEtisalatUAE" class="card_welcomeText_secondary_text">
            {{
              $t(
                `Free for ${plan.trialperioddays} days then AED ${
                  plan.amount / 100
                } / ${
                  plan.planinterval === "WEEK" ? "Week" : "Month"
                }, VAT Inclusive`
              )
            }}
          </p>
          </div>
          <p class="card_verification_code_text">
            {{ $t("Please enter the verification code sent to") }}
            <br />
            <span dir="ltr"> {{ country_code }} {{ mobile_number }} </span>
          </p>
          <!-- <p class="card_error_message"> a new message was sent</p> -->
          <p class="card_error_message">{{ error_Text }}</p>
          <div
            dir="ltr"
            class="card_otp_setup"
            :class="[localDisplayLang === 'ara' ? 'card_otp_setup-ara' : '']"
          >
            <input
              v-for="index in otpBlockDigits"
              :key="index"
              :id="`codeBox${index}`"
              type="number"
              class="card_journey-otp"
              :class="popupOTPClass"
              maxlength="1"
              v-model="otp[index]"
              @keyup="onKeyUpEvent(index, $event)"
              @focus="onFocusEvent(index)"
              @beforeinput="specialCharValidation($event)"
              onkeypress="return this.value.length > 0 ? false :true"
            />

            <!-- For Zain KSA -->
            <!-- <input
              v-if="extraPinBlock"
              id="codeBox5"
              type="number"
              :class="popupOTPClass"
              class="card_journey-otp"
              v-model="otp.five"
              maxlength="1"
              @keyup="onKeyUpEvent(5, $event)"
              @focus="onFocusEvent(5)"
              @beforeinput="specialCharValidation($event)"
              onkeypress="return this.value.length > 0 ? false :true"
            />
            <input
              v-if="extraPinBlock"
              id="codeBox6"
              type="number"
              :class="popupOTPClass"
              class="card_journey-otp"
              v-model="otp.six"
              maxlength="1"
              @keyup="onKeyUpEvent(6, $event)"
              @focus="onFocusEvent(6)"
              @beforeinput="specialCharValidation($event)"
              onkeypress="return this.value.length > 0 ? false :true"
            /> -->
          </div>
          <p class="card_send_again">
            <span
              :class="[
                enableSendAgain ? 'enableSendAgain' : 'disableSendAgain',
              ]"
              @click="resendOtp()"
            >
              {{ $t("Send Again") }}</span
            >
            <span v-if="!enableSendAgain" class="card_resend_counter">{{
              resendCounter + " " + "sec"
            }}</span>
          </p>
          <div
            class="card_button"
            :class="[
              localDisplayLang === 'ara'
                ? 'card_button_verify_right'
                : 'card_button_verify',
            ]"
          >
            <button v-if="!btnLoader" @click="actVerify()">
              {{ isEtisalatUAE ? $t("Subscribe") : $t("verify") }}
            </button>
            <Loading v-else></Loading>
          </div>

          <div v-if="isTpay || isEtisalatUAE" class="card_info">
            <p v-if="notCustomer" class="primary">
              {{ notCustomer }}
              <span class="click" @click="redirectToSubscriptionPage()">{{
                $t("Click here")
              }}</span>
            </p>
            <p class="secondary" v-html="planTermsText(plan)"></p>
            <!-- <p class="secondary">
              {{ planTermsText(plan) }}
            </p> -->
          </div>
        </div>

        <div class="card_paymentProgress" v-else>
          <p class="card_paymentProgress_please_wait">
            {{ $t("please wait") }}
          </p>
          <p class="card_paymentProgress_payment_progress">
            {{ $t("your payment is in progress") }}
          </p>
          <p class="card_paymentProgress_payment_progress">
            {{ $t("Loading") }}
          </p>
          <img
            class="card_paymentProgress_loading_gif"
            src="@/assets/gif/30.gif"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus.js";
import { mapActions, mapGetters } from "vuex";
import { store } from "@/store/store";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import { showSnackBar } from "../../utilities";
import { getTelcoTpaySdkUrlGeneration } from "@/utilities";

export default {
  props: {
    planList: {
      type: Array,
    },
    selectedGateway: {
      type: Object,
    },
  },
  data() {
    return {
      days: 7,
      inputs: [],
      plans: [],
      plan: null,
      selectedIndex: null,
      localDisplayLang: null,
      btnLoader: false,
      mobile_number: "",
      country_code: "",
      popupOTPClass: "",
      otp: { 1: "", 2: "", 3: "", 4: "", 5: "", 6: "" },
      isOTPActive: false,
      extraPinBlock: false,
      otpLoader: false,
      counter: 10,
      verifyNumber: true,
      paymentInitiatedResponse: null,
      error_Text: null,
      resendCounter: 60,
      enableSendAgain: false,
      countryList: [],
      isTpay: false,
      otpBlockDigits: 4,
      tpayProvider: null,
      providername: null,
      tpayProviders: [
        {
          providerName: "Orange",
          providerId: "orange",
          operatorCode: "60201",
        },
        {
          providerName: "Vodafone",
          providerId: "vodafone",
          operatorCode: "60202",
        },
        {
          providerName: "WE",
          providerId: "we",
          operatorCode: "60204",
        },
        {
          providerName: "Etisalat",
          providerId: "etisalat",
          operatorCode: "60203",
        },
      ],

      tpaySession: null,
      isEtisalatUAE: false,
    };
  },
  computed: {
    ...mapGetters(["getCountry", "appConfig"]),
    notCustomer() {
      if (this.routename === "ooredoo-oman" || this.routename === "ooredoo-qatar" || this.routename === "ooredoo-palestine") {
        return this.$t("Not a OOREDOO customer?");
      } else if (this.routename === "du-uae") {
        return this.$t("Not a DUUAE customer?");
      } else if (this.routename === "vodafone-egypt") {
        return this.$t("Not a Vodafone - Egypt customer?");
      } else if (this.routename === "we-egypt") {
        return this.$t("Not a WE - Egypt customer?");
      } else if (this.routename === "etisalat-egypt") {
        return this.$t("Not a Etisalat - Egypt customer?");
      } else if (this.routename === "orange-egypt") {
        return this.$t("Not a Orange - Egypt customer?");
      } else if (this.routename === "etisalat-uae") {
        return this.$t("Not a Etisalat customer?");
      } else if (this.routename === "zain-ksa") {
        return null;
      } else if (this.routename === "zain-iraq") {
        return null;
      } else if (this.routename === "vodafone-qatar") {
        return null;
      }
    },

    redirectToSubscriptionPage() {
      this.$router.push("/profile");
    },

    checkPartner() {
      if (
        this.$router.currentRoute.params.id === "stc-bahrain" ||
        this.$router.currentRoute.params.id === "ooredoo-oman" ||
				this.$router.currentRoute.params.id === "ooredoo-palestine" ||
        this.$router.currentRoute.params.id === "mobily-ksa" ||
        this.$router.currentRoute.params.id === "ooredoo-qatar" ||
        this.$router.currentRoute.params.id == "vodafone-egypt" ||
        this.$router.currentRoute.params.id == "we-egypt" ||
        this.$router.currentRoute.params.id == "orange-egypt" ||
        this.$router.currentRoute.params.id == "etisalat-egypt" ||
        this.$router.currentRoute.params.id === "etisalat-uae" ||
        this.$router.currentRoute.params.id === "zain-ksa" ||
				this.$router.currentRoute.params.id === "zain-iraq" || 
        this.$router.currentRoute.params.id === "vodafone-qatar"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    error_Text(val) {
      if (val) {
        setTimeout(() => {
          this.error_Text = null;
        }, 2000);
      }
    },
    mobile_number(val) {
      if (val) {
        while (val.charAt(0) === "0") {
          val = val.substring(1);
        }
        this.mobile_number = val;
      }
    },
  },
  created() {
    if (
      this.$router.currentRoute.params.id == "vodafone-egypt" ||
      this.$router.currentRoute.params.id == "we-egypt" ||
      this.$router.currentRoute.params.id == "orange-egypt" ||
      this.$router.currentRoute.params.id == "etisalat-egypt" ||
       this.$router.currentRoute.params.id === "mobily-ksa"
    ) {
      this.otpBlockDigits = 6;
    }else if(this.$router.currentRoute.params.id === "ooredoo-palestine" || this.$router.currentRoute.params.id === "zain-iraq" ){
			this.otpBlockDigits = 5;
		}

    console.log("Telco plan card otp", this.planList);

    this.routename = this.$router.currentRoute.params.id;
    this.providername = this.$router.currentRoute.params.provider;

    // this.$nextTick(() => {
    //  this.getTpayHeader();
    // })
    // setTimeout(function(){
    // 	this.getTpayHeader();
    // },400)
  if (!(this.$router.currentRoute.params.id === "mobily-ksa")) {
    this.loadTPaySDK().then((status) => {
      if (status) {
        this.getTpayHeader();
      }
      // else {
      // alert("failed to Load Tpay SDK")
      // }
    });
  }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.isEtisalatUAE = this.routename === "etisalat-uae" ? true : false;

    this.loadPlans();

    this.getCountries();

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  // mounted(){
  // this.getTpayHeader();
  // },
  methods: {
    ...mapActions(["paymentInitiation"]),

    getCurrency(currency) {
      if (currency === "EGP") {
        return `Egyptian pounds`;
      } else {
        return currency;
      }
    },
    loadTPaySDK() {
      // Tpay - Fraud detection SDK
      return new Promise((resolve, reject) => {
        const tPayPrivateKey = "Q0wSaSbNzlP84FjiOdnJ";
        const tPayPublicKey = "GsEmtn4K86nYESBEohIZ";

        const baseURLtPay = this.appConfig.paymentSDKs.tpayBaseURL;

        const telcoTpayCdnUrl = getTelcoTpaySdkUrlGeneration(
          tPayPrivateKey,
          tPayPublicKey,
          baseURLtPay
        );

        let telcoTpayCdn = document.createElement("script");

        telcoTpayCdn.setAttribute("src", telcoTpayCdnUrl);
        document.head.appendChild(telcoTpayCdn);
        console.log("tpayyy",telcoTpayCdnUrl, telcoTpayCdn)
        telcoTpayCdn.onload = () => {
          resolve(true);
        };
        telcoTpayCdn.onerror = () => {
          reject(false);
        };
        // if(document.head.appendChild(telcoTpayCdn)){
        // 	resolve(true)
        // }else {
        // 	reject(false)
        // }
      });
    },

    getTpayHeader() {
      if (
        this.$router.currentRoute.params.id == "vodafone-egypt" ||
        this.$router.currentRoute.params.id == "we-egypt" ||
        this.$router.currentRoute.params.id == "orange-egypt" ||
        this.$router.currentRoute.params.id == "etisalat-egypt"
      ) {
        this.isTpay = true;
        this.tpayProvider = this.$router.currentRoute.params.provider;

        TPay.HeaderEnrichment.init({
          operatorCode: `'${Number(this.getOperatorCodeTpay())}'`,
        });

        if (this.isTpay) {
          this.otpBlockDigits = 6;
        }
      }
    },

    getOperatorCodeTpay() {
      if (this.routename.includes("vodafone")) {
        return "60202";
      } else if (this.routename.includes("orange")) {
        return "60201";
      } else if (this.routename.includes("we")) {
        return "60204";
      } else if (this.routename.includes("etisalat")) {
        return "60203";
      }
    },

    loadPoster() {
      if (this.routename === "ooredoo-oman") {
        return require("@/assets/icons/ooredoo.svg");
      } else if (this.routename === "ooredoo-palestine") {
        return require("@/assets/icons/oredoo-palestine.png");
      }  else if (this.routename === "mobily-ksa") {
       return require("@/assets/icons/mobily.png");
      }
			else if (this.routename === "zain-ksa") {
        return require("@/assets/icons/Zain_KSA_logo.svg");
      } 
			else if (this.routename === "zain-iraq") {
        return require("@/assets/icons/zain-iraq.png");
      } else if (this.routename === "vodafone-qatar") {
        return require("@/assets/icons/tpay-vodafone.png");
      } 
       else if (this.routename === "du-uae") {
        return require("@/assets/icons/du-logo.png");
      } else if (this.routename === "vodafone-egypt") {
        return require("@/assets/icons/tpay-vodafone.png");
      } else if (this.routename === "we-egypt") {
        return require("@/assets/icons/tpay-we.png");
      } else if (this.routename === "etisalat-egypt") {
        return require("@/assets/icons/ET_Logo.png");
      } else if (this.routename === "orange-egypt") {
        return require("@/assets/icons/tpay-orange.png");
      } else if (this.routename === "etisalat-uae") {
        return require("@/assets/icons/etisalat-logo-new.png");
      }
    },

    redirectTermsAndConditions() {
      if (this.localDisplayLang === "ara") {
        this.$router.push("/TermsOfUse-ar");
      } else {
        this.$router.push("/TermsOfUse");
      }
    },

    exitPage() {
      return this.$router.push("/profile");
    },

    planTermsText(plan) {
      console.log("PLANNN", plan)
      if (
        plan.currency == "OMR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK"
      ) {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 2 days Free Trial once. After Free Trial you will be charged OMR 0.7 / week. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 12 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (
        plan.currency == "OMR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RTNTSCGI"
      ) {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 30 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } else if (
        plan.currency == "OMR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH"
      ) {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 7 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } 
			else if (plan.currency == "ILS" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. 
<br>
You will be getting a 2 days Free Trial once.
<br>
After Free Trial you will be charged 1.49 USD / week. Price includes 5% VAT,
Charges will be deducted from Ooredoo balance.
<br>
Your subscription will renew automatically until cancelled. 
<br>
To cancel, Send UNSUB NPW to 6105. 
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay
your bill.`;
      } 
      else if (plan.currency == "ILS" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 30 days Free Trial once. After Free Trial you will be charged OMR 2 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send UNSUB 13 to 92192. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      } 
      else if (plan.currency == "ILS" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. 
<br> 
You will be getting a 7 days Free Trial once.
<br>
After Free Trial you will be charged 4.49 USD / month, Price includes 5% VAT,
Charges will be deducted from Ooredoo balance.
<br>
Your subscription will renew automatically until cancelled.
<br>
To cancel, Send UNSUB NPM to 6105.
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay 
your bill.`;
      } 

		else if (plan.currency == "IQD" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. 
<br>
You will be getting a 2 days Free Trial once.
<br>
After Free Trial you will be charged 1800 IQD / week. Price includes 5% VAT,
Charges will be deducted from Zain balance.
<br>
Your subscription will renew automatically until cancelled. 
<br>
To cancel, Send 05 by message to 4089. 
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay
your bill.`;
      }  else if (plan.currency == "IQD" && this.localDisplayLang === "eng" && plan.planinterval === "MONTH") {
        return `Now stream movies, TV series, Kids' shows. 
<br> 
You will be getting a 7 days Free Trial once.
<br>
After Free Trial you will be charged 3600 IQD / month, Price includes 5% VAT,
Charges will be deducted from Zain balance.
<br>
Your subscription will renew automatically until cancelled.
<br>
To cancel, Send 05 by message to 4089.
<br>
To use this service, you must be 18+ years old
or have received permission from your parents or person authorized to pay 
your bill.`;
      } 

      else if (plan.currency === "QAR" && this.localDisplayLang === "eng" && plan.planinterval === "WEEK") {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 2 days Free Trial once. After Free Trial you will be charged QAR 7 / week. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send STOP to 92964. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      }
      // Qatar - Month
      else if (
        plan.currency === "QAR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH"
      ) {
        return `Now stream movies, TV series, Kids' shows. You will be getting a 7 days Free Trial once. After Free Trial you will be charged QAR 20 / month. Price includes 5% VAT. Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, Send STOP to 92964. To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill`;
      }

      // Saudi Arabia - Week
      else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK"
      ) {
        return `Once your 2 days free trial ends, you will be charged 7 AED weekly.
<br>
Renewals will be automatic every weekly.
<br>
To cancel the service anytime, just send C NPW to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      }
      // Saudi Arabia - Month
      else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH"
      ) {
        return `Once your 7 days free trial ends, you will be charged 20 AED monthly.
<br>
Renewals will be automatic every monthly.
<br>
To cancel the service anytime, just send C NPM to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      }

      // Tpay - Vodafone - Week
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        this.routename === "vodafone-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>16 Egyptian pounds Weekly</font></b> auto-renewed. To cancel your subscription, for Vodafone subscribers, please send UNSUB NOOPL to 2303 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle.`;
      }
      // Tpay - Vodafone - Month
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        this.routename === "vodafone-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>40 Egyptian pounds Monthly</font></b> auto-renewed. To cancel your subscription, for Vodafone subscribers, please send UNSUB NOOPL to 2303 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle.`;
      }
      // Tpay - Vodafone - Day
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "DAY" &&
        this.routename === "vodafone-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>4 Egyptian pounds Daily</font></b> auto-renewed. To cancel your subscription, for Vodafone subscribers, please send UNSUB NOOPL to 2303 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle.`;
      }

      // Tpay - Orange - Week
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        this.routename === "orange-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>16 Egyptian pounds Weekly</font></b> auto-renewed. To cancel your subscription, for Orange subscribers, please send UNSUB NOOPL to 5030 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }
      // Tpay - Orange - Month
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        this.routename === "orange-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>40 Egyptian pounds Monthly</font></b> auto-renewed. To cancel your subscription, for Orange subscribers, please send UNSUB NOOPL to 5030 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }
      // Tpay - Orange - Day
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "DAY" &&
        this.routename === "orange-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>4 Egyptian pounds Daily</font></b> auto-renewed. To cancel your subscription, for Orange subscribers, please send UNSUB NOOPL to 5030 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }

      // Tpay - WE - Week
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        this.routename === "we-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>16 Egyptian pounds Weekly</font></b> auto-renewed. To cancel your subscription, for WE subscribers, please send UNSUB NOOPL to 4041 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }
      // Tpay - WE - Month
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        this.routename === "we-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>40 Egyptian pounds Monthly</font></b> auto-renewed. To cancel your subscription, for WE subscribers, please send UNSUB NOOPL to 4041 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }
      // Tpay - WE - Day
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "DAY" &&
        this.routename === "we-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>4 Egyptian pounds Daily</font></b> auto-renewed. To cancel your subscription, for WE subscribers, please send UNSUB NOOPL to 4041 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }

      // Tpay - Etisalat - Week
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        this.routename === "etisalat-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>16 Egyptian pounds Weekly</font></b> auto-renewed. To cancel your subscription, for Etisalat subscribers, please send UNSUB NOOPL to 1722 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }
      // Tpay - Etisalat - Month
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        this.routename === "etisalat-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>40 Egyptian pounds Monthly</font></b> auto-renewed. To cancel your subscription, for Etisalat subscribers, please send UNSUB NOOPL to 1722 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }
      // Tpay - Etisalat - Day
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "DAY" &&
        this.routename === "etisalat-egypt"
      ) {
        return `You will subscribe in Noorplay for <b><font color='red'>4 Egyptian pounds Daily</font></b> auto-renewed. To cancel your subscription, for Etisalat subscribers, please send UNSUB NOOPL to 1722 for free. To cancel from the site, please go to https://www.noorplay.com/en/profile and click on  Cancel. For any inquires please contact by email at (support@noorplay.com) Internet usage is deducted from your internet bundle. `;
      }

      // Oman - Week - AR
      else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "OUIDPSI5"
      ) {
        return `Once your 2 days free trial ends, you will be charged 7 AED weekly.
<br>
Renewals will be automatic every weekly.
<br>
To cancel the service anytime, just send C NPW to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "RMZJIBIP"
      ) {
        return `Once your 7 days free trial ends, you will be charged 20 AED monthly.
<br>
Renewals will be automatic every monthly.
<br>
To cancel the service anytime, just send C NPM to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid === "OUIDPSI5"
      ) {
        return `Once your 2 days free trial ends, you will be charged 7 AED weekly.
<br>
Renewals will be automatic every weekly.
<br>
To cancel the service anytime, just send C NPW to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RMZJIBIP"
      ) {
        return `Once your 7 days free trial ends, you will be charged 20 AED monthly.
<br>
Renewals will be automatic every monthly.
<br>
To cancel the service anytime, just send C NPM to 1111.
<br>
Free trial is applicable for new customers only.
<br>
Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content.
<br>
For support, please contact: support@noorplay.com`;
      }
      //
      else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "ZCMPZKYO"
      ) {
        return `Once your 7 days free trial ends, you will be charged 20 SAR monthly
              Renewals will be automatic every month
              To cancel the service anytime, just send "UNSUBNM" to 712799
              Free trial is applicable for new customers only`;
      } else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "KTZBZ1PY"
      ) {
        return `Once your 2 days free trial ends, you will be charged 7 SAR weekly
              Renewals will be automatic every week
              To cancel the service anytime, just send "UNSUBNW" to 712799
              Free trial is applicable for new customers only`;
      } else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH" &&
        plan.planid == "ZCMPZKYO"
      ) {
        return `Once your 3 days free trial ends, you will be charged 20 SAR monthly
              Renewals will be automatic every month
              To cancel the service anytime, just send "U5" to 608197
              Free trial is applicable for new customers only`;
      } else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK" &&
         plan.planid == "KTZBZ1PY"
      ) {
        return `Once your 1 days free trial ends, you will be charged 7 SAR weekly
              Renewals will be automatic every week
              To cancel the service anytime, just send "U5" to 608197
              Free trial is applicable for new customers only`;
      }
      
      else if (
        plan.currency == "IQD" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "MONTH"
      ) {
        return `Once your 7 days free trial ends, you will be charged 3600 IQD monthly
              Renewals will be automatic every month
              To cancel the service anytime, just Send 05 by message to 4089
              Free trial is applicable for new customers only`;
      } else if (
        plan.currency == "IQD" &&
        this.localDisplayLang === "eng" &&
        plan.planinterval === "WEEK"
      ) {
        return `Once your 2 days free trial ends, you will be charged 1800 IQD weekly
              Renewals will be automatic every week
              To cancel the service anytime, just Send 05 by message to 4089
              Free trial is applicable for new customers only`;
      }
      //
      else if (
        plan.currency == "OMR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK"
      ) {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 2 يوم ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 0.7 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 12" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (
        plan.currency == "OMR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RTNTSCGI"
      ) {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 30 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (
        plan.currency == "OMR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH"
      ) {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 7 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      }
			      else if (plan.currency == "ILS" && this.localDisplayLang === "ara" && plan.planinterval === "WEEK") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة.
<br>
ستحصل على (2 يوم) تجربة مجانية.
<br>
عند إنتهاء التجربة المجانية سيتم خصم 1.49 دولارأسبوعياً 
متضمن 5% ضريبة وسيتم خصم المبلغ من رصيدك في Ooredoo
<br>
سيتم تجديد الإشتراك تلقائياً حتى يتم الغاؤه.
<br>
بإمكانك إلغاء الإشتراك في اي وقت عبر إرسال 
" UNSUB NPW" للرقم 6105.
لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن
والديك أو شخص مخوّل بالدفع.`;
      } 
      
      else if (plan.currency == "ILS" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH" && plan.planid === "RTNTSCGI") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 30 أيام ) تجربة مجانية, عند إنتهاء التجربة المجانية سيتم خصم 2 ريال عماني أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUB 13" للرقم 92192
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      }

      else if (plan.currency == "ILS" && this.localDisplayLang === "ara" && plan.planinterval === "MONTH") {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة.
<br>
ستحصل على (7 يوم) تجربة مجانية.
<br>
عند إنتهاء التجربة المجانية سيتم خصم 4.49 دولارشهرياً 
متضمن 5% ضريبة و سيتم خصم المبلغ من رصيدك في Ooredoo
<br> 
سيتم تجديد الإشتراك تلقائياً حتى يتم الغاؤه.
<br>
بإمكانك إلغاء الإشتراك في اي وقت عبر إرسال 
" UNSUB NPM" للرقم 6105.
لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن
والديك أو شخص مخوّل بالدفع.`;
      }
      // Qatar - Week - AR
      else if (
        plan.currency === "QAR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK"
      ) {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 2 يوم ) تجربة مجانية لمرة واحدة, عند إنتهاء التجربة المجانية سيتم خصم 7 ريال قطري أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP" للرقم 92964
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      }
      // Qatar - Month - AR
      else if (
        plan.currency === "QAR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH"
      ) {
        return `يمكنك الآن مشاهدة العديد من المسلسلات, الأفلام وبرامج الأطفال المشوّقة
          ستحصل على ( 7 أيام ) تجربة مجانية لمرة واحدة, عند إنتهاء التجربة المجانية سيتم خصم 20 ريال قطري أسبوعياً متضمن %5 ضريبة
          سيتم خصم المبلغ من رصيدك في Ooredoo
          سيتم تجديد الإشتراك تلقائياً حتى يتم إلغاؤه, بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "STOP" للرقم 92964
          لإستخدام الخدمة يجب أن يكون عمرك 18 سنة أو أكثر أو حصلت على إذن والديك أو شخص مخوّل بالدفع.`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "OUIDPSI5"
      ) {
        return `عند إنتهاء التجربة المجانية ( 2 يوم ), سيتم احتساب رسوم الخدمة ( 7 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل أسبوع بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPW للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "RMZJIBIP"
      ) {
        return `عند إنتهاء التجربة المجانية ( 7 أيام ) سيتم احتساب رسوم الخدمة ( 20 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل شهر بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPM للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid === "OUIDPSI5"
      ) {
        return `عند إنتهاء التجربة المجانية ( 2 يوم ), سيتم احتساب رسوم الخدمة ( 7 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل أسبوع بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPW للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com	`;
      } else if (
        plan.currency == "AED" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid === "RMZJIBIP"
      ) {
        return `عند إنتهاء التجربة المجانية ( 7 أيام ) سيتم احتساب رسوم الخدمة ( 20 درهم إماراتي ) شهريا
<br>
سيتم تجديد الإشتراك بشكل تلقائي
<br>
كل شهر بدون عقود أو قيود بإمكانك إلغاء الإشتراك مجانا في أي وقت عبر إرسال C NPM للرقم 1111 
<br>
يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
<br>
في حال أي سؤال أو إستفسار نرجوا التواصل عبر support@noorplay.com`;
      }
      //
      else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid !== "KTZBZ1PY"
      ) {
        return `عند إنتهاء الفترة التجريبية ( 2 أيام ) سيتم خصم 7 ريال سعودي اسبوعيا
              سيتم تجديد الإشتراك تلقائيا كل اسبوع
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUBNW" للرقم 712799
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      } else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        plan.planid !== "ZCMPZKYO"
      ) {
        return `عند إنتهاء الفترة التجريبية ( 7 أيام ) سيتم خصم 20 ريال سعودي شهريا
              سيتم تجديد الإشتراك تلقائيا كل شهر
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "UNSUBNM" للرقم 712799
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      }       else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        plan.planid == "KTZBZ1PY"
      ) {
        return `عند إنتهاء الفترة التجريبية ( 1 أيام ) سيتم خصم 7 ريال سعودي اسبوعيا
              سيتم تجديد الإشتراك تلقائيا كل اسبوع
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "U5" للرقم 608197
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      } else if (
        plan.currency == "SAR" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
         plan.planid == "ZCMPZKYO"
      ) {
        return `عند إنتهاء الفترة التجريبية ( 3 أيام ) سيتم خصم 20 ريال سعودي شهريا
              سيتم تجديد الإشتراك تلقائيا كل شهر
              بإمكانك إلغاء الإشتراك في أي وقت عبر إرسال "U5" للرقم 608197
              التجربة المجانية متوفرة للمشتركين الجدد فقط`;
      } 
      
      else if (
        plan.currency == "IQD" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK"
      ) {
        return `عند انتهاء الفترة التجريبية (يومين) سيتم خصم 1,800 ريال سعودي أسبوعياً
              سيتم تجديد الاشتراك تلقائيا كل أسبوع
              يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال 05 برسالة إلى 4089
              النسخة التجريبية المجانية متاحة للمشتركين الجدد فقط`;
      } else if (
        plan.currency == "IQD" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH"
      ) {
        return `عند انتهاء الفترة التجريبية (7 أيام) سيتم خصم 3,600 ريال سعودي شهرياً
              سيتم تجديد الاشتراك تلقائيا كل شهر
              يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال 05 برسالة إلى 4089
              النسخة التجريبية المجانية متاحة للمشتركين الجدد فقط`;
      }

      // Tpay - Vodafone - Week - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        this.routename === "vodafone-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 16 جنيه مصري اسبوعيا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 2303 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - Vodafone - Month - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        this.routename === "vodafone-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 40 جنيه مصري شهريا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 2303 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - Vodafone - Day - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "DAY" &&
        this.routename === "vodafone-egypt"
      ) {
        return ` ستشترك في نوربلاي مقابل <b><font color='red'> 4 جنيه مصري يوميا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 2303 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }

      // Tpay - Orange - Week - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        this.routename === "orange-egypt"
      ) {
        return ` ستشترك في نوربلاي مقابل <b><font color='red'> 16 جنيه مصري اسبوعيا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 5030 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - Orange - Month - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        this.routename === "orange-egypt"
      ) {
        return ` ستشترك في نوربلاي مقابل <b><font color='red'> 40 جنيه مصري شهريا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 5030 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - Orange - Day - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "DAY" &&
        this.routename === "orange-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 4 جنيه مصري يوميا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 5030 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }

      // Tpay - WE - Week - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        this.routename === "we-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 16 جنيه مصري اسبوعيا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 4041 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - WE - Month - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        this.routename === "we-egypt"
      ) {
        return ` ستشترك في نوربلاي مقابل <b><font color='red'> 40 جنيه مصري شهريا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 4041 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - WE - Day - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "DAY" &&
        this.routename === "we-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 4 جنيه مصري يوميا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 4041 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }

      // Tpay - Etisalat - Week - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "WEEK" &&
        this.routename === "etisalat-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 16 جنيه مصري اسبوعيا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 1722 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - Etisalat - Month - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "MONTH" &&
        this.routename === "etisalat-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 40 جنيه مصري شهريا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 1722 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
      // Tpay - Etisalat - Day - AR
      else if (
        plan.currency == "EGP" &&
        this.localDisplayLang === "ara" &&
        plan.planinterval === "DAY" &&
        this.routename === "etisalat-egypt"
      ) {
        return `ستشترك في نوربلاي مقابل <b><font color='red'> 4 جنيه مصري يوميا </font></b> وسيتم تجديد الإشتراك تلقائيا
<br>
يمكنك إلغاء إشتراكك في أي وقت عبر  إرسال UNSUB NOOPL إلى 1722 مجانا
<br>
للإلغاء من الموقع الإلكتروني ، يرجى الانتقال إلى (https://www.noorplay.com/en/profile) والنقر على إلغاء
<br>
لأية استفسارات ، يرجى التواصل معنا عبر البريد الإلكتروني على support@noorplay.com
<br>
يتم خصم استخدام الإنترنت من باقة الإنترنت الخاصة بك`;
      }
    },

    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },

    onKeyUpEvent(index, event) {
      console.log("event", event);
      if (event.defaultPrevented || (event.type === "keyup" && this.isInput)) {
        return;
      } else {
        let _this = this;
        let eventType = event.inputType ? event.inputType : null;
        setTimeout(function () {
          const eventCode =
            (event && event.which) || event.keyCode
              ? event.which || event.keyCode
              : null;
          index = Number.isInteger(index)
            ? index
            : parseInt(index.match(/\d+/g)[0]);
          if (_this.getCodeBoxElement(index).value.length === 1) {
            if (index !== _this.otpBlockDigits) {
              console.log(
                "THE INDEX and NUMBER--",
                index,
                _this.otpBlockDigits
              );
              _this.getCodeBoxElement(index + 1).focus();
            }
            // } else {
            // getCodeBoxElement(index).blur();
            // Submit code
            // console.log("submit code ");
            // }
          }
          if (_this.getCodeBoxElement(index).value.length > 1) {
            if (index !== 4) {
              _this.getCodeBoxElement(index + 1).focus();
            } else {
              _this.getCodeBoxElement(index).blur();
            }
          }
          if (
            (eventCode === 8 || eventType === "deleteContentBackward") &&
            index !== 1
          ) {
            _this.getCodeBoxElement(index - 1).focus();
          }
          if (
            eventCode === 13 ||
            (eventType === "insertLineBreak" && index === 4)
          ) {
            document.getElementById("mobile-journey-otp").click();
          }
        }, 10);
      }
    },

    onFocusEvent(index) {
      for (let item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },

    specialCharValidationMobile(e) {
      let code = e.data;
      if (!code) return;
      code = code[code.length - 1];

      if (!this.listOfSpecialMobile(code.charCodeAt())) {
        e.preventDefault();
        return false;
      }
    },

    listOfSpecialMobile(charCode) {
      if (charCode >= 65 && charCode <= 122) {
        return false;
      }
      if (charCode != 69 && charCode != 101) {
        for (let i = 65, k = 97, j = 48; i <= 90; i++, j++, k++) {
          if (charCode == i || charCode == k || (j <= 57 && charCode == j)) {
            return true;
            break;
          }
        }
      }
      return false;
    },

    specialCharValidation(e) {
      if (e.target.value.length === 1 && event.inputType === "insertText") {
        e.preventDefault();
        return false;
      }
      let code = e.data;
      if (!code) return;
      code = code[code.length - 1];

      if (!this.listOfSpecial(code.charCodeAt())) {
        e.preventDefault();
        return false;
      }
    },

    listOfSpecial(charCode) {
      // console.log(charCode)
      // if(charCode == 95 || charCode == 64 || charCode == 46) {
      // 	return true;
      // }
      if (charCode != 69 && charCode != 101) {
        for (let i = 65, k = 97, j = 48; i <= 90; i++, j++, k++) {
          if (charCode == i || charCode == k || (j <= 57 && charCode == j)) {
            return true;
            break;
          }
        }
      }
      return false;
    },

    calculatePrice(item) {
			if (item.currency == "ILS") {
				return item.displayamount / 100;
			} else {
				return item.amount / 100;
			}
    },

    loadPlans() {
      this.plans = this.planList;
      this.planList.forEach((element, index) => {
        if (element.planinterval === "MONTH") {
          this.selectedIndex = index;
          this.plan = this.planList[index];
        }
        else if (element.planinterval === "WEEK") {
              this.selectedIndex = index;
              this.plan = this.planList[index];
            }
      });
    },

    getCountries() {
      store
        .dispatch("getCountryList")
        .then((response) => {
          this.countryList = response;
          // if (this.getCountry) {
          this.fetchCurrentCountryCode();
          // }
        })
        .catch((error) => console.log(error));
    },
    fetchCurrentCountryCode() {
      let index = this.countryList.findIndex((element) => {
        console.log("THE COUNTRY CODE --", this.planList[0].country[0]);

        // console.log("THE COUNTRY CODE NOW", this.planList);
        // return element.alpha2Code === this.getCountry.CountryCode;
        return element.code === this.planList[0].country[0];
      });

      if (index > -1) {
        this.country_code = this.countryList[index].dial_code
          ? this.countryList[index].dial_code
          : "";
      }
    },

    planSelect(item, index) {
      console.log("THE SELECTED PLAN --- > ", item, index);

      this.selectedIndex = index;
      this.plan = item;
    },

    createFecthApiForSTCPaymentInit(payload) {
      return store.dispatch("telcoPaymentInititation", payload);
    },

   async constructInitPayload(plan, selectedGateway) {
		console.log("INIT-PLAN", plan)
    // return new Promise((resoved, reject) => {
			const payload = {
        gatewayid: selectedGateway.gatewayid,
        params: {
          amount: plan.amount,
          currency: plan.currency,
          devicetype: "WEB",
          transactionpurpose: "SUBSCRIPTION",
          transactionmode: "CC",
          availabilityid: plan.availabilityset[0],
          planid: plan.planid,
          mobileno: this.country_code + this.mobile_number,
          displaylanguage: this.localDisplayLang,
        },
      };
			console.log("INIT-PAYLOAD",payload)

      if (this.isTpay) {
        payload.params.tpaySession = await TPay.HeaderEnrichment.sessionToken();
      }
      return payload;
    },
    redirectToHome(resp) {
      const errorMessage = "This user already subscribed to the given product";
      if (resp.reason.toLowerCase() === errorMessage.toLowerCase()) {
        setTimeout(() => {
          // this.$emit("openAuth", true);
          this.$router.push("/");

          //open lookup popup.
          let payload = {
            state: true,
            formType: "lookup",
          };
          eventBus.$emit("authPopup", payload);
        }, 4000);
      }
    },

    paymentInitRequest() {
      this.btnLoader = true;
      // let payload = this.constructInitPayload(this.plan, this.selectedGateway);
      this.constructInitPayload(this.plan, this.selectedGateway).then(
        (payload) => {
          if (this.routename === "du-uae") {
            let currentDomain = window.location.origin;
            let routeLocale = this.localDisplayLang === "ara" ? "ar" : "en";
            payload.params.client_return_url =
              currentDomain + `/${routeLocale}/telcoPaymentHandler`;
          }

          this.createFecthApiForSTCPaymentInit(payload)
            .then((response) => {
              this.btnLoader = false;
              if (response.errorcode) {
                this.snackbarMessage(response.reason);

                this.redirectToHome(response);
              } else {
                if (this.routename == "du-uae") {
                  console.log("du-uae payment init response", response);
                  let redirectUrl =
                    response.referencedata.paymenturl + "&locale" + `=en`;
                  window.open(redirectUrl, "_self");
                } else {
                  this.paymentInitiatedResponse = response;
                  if (!this.isOTPActive) {
                    this.isOTPActive = true;
                  }
                  if (this.isOTPActive) {
                    this.decrementResendCounter();
                  }
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      );
    },

    startFreeTrail() {
      if (this.isTpay && this.validateMobile(this.mobile_number) == true) {
        // console.log("THE TPAY TEST -> ", TPay);
        this.paymentInitRequest();

        let data = {
          telco_partner:
            this.$route.params && this.$route.params.id
              ? this.$route.params.id
              : "",
          plan_name: this.plan.planname,
          plan_id: this.plan.planid,
        };

        this.telco_trial_initiated(data);

        this.setSubmitAnalytics();

        localStorage.setItem("TelcoPlan", JSON.stringify(data));
      } else if (this.validateMobile(this.mobile_number) == true) {
        this.paymentInitRequest();

        let data = {
          telco_partner:
            this.$route.params && this.$route.params.id
              ? this.$route.params.id
              : "",
          plan_name: this.plan.planname,
          plan_id: this.plan.planid,
        };

        this.telco_trial_initiated(data);

        let logData = {
          mobile_number: this.country_code + this.mobile_number,
          telco_partner:
            this.$route.params && this.$route.params.id
              ? this.$route.params.id
              : "",
        };

        this.telco_data_log(logData);

        this.setSubmitAnalytics();

        localStorage.setItem("TelcoPlan", JSON.stringify(data));
      } else {
        this.snackbarMessage(this.validateMobile(this.mobile_number));
      }
    },

    setSubmitAnalytics() {
      const data = {
        mobile: this.mobile_number,
      };

      console.log("The Mobile number Analytics", data);

      this.telco_page_submission_tracker(data);
    },

    resendOtp() {
      if (this.enableSendAgain) {
        this.paymentInitRequest();
      }
    },

    actVerify() {
      this.btnLoader = true;
      let otp =
        this.otp[1] +
        this.otp[2] +
        this.otp[3] +
        this.otp[4] +
        this.otp[5] +
        this.otp[6];

      console.log("THE NEW PINNN -- ", otp);
      if (otp && otp.length === this.otpBlockDigits) {
        setTimeout(() => {
          this.btnLoader = false;
          this.verifyNumber = false;
          this.decrementCounter(otp);
        }, 2000);
      } else {
        this.btnLoader = false;
        this.error_Text = this.$t("Please enter valid OTP");
      }
    },

    decrementResendCounter() {
      this.resendCounter = 60;
      if (this.resendCounter > 1) {
        this.enableSendAgain = false;

        let timer = setInterval(() => {
          this.resendCounter--;
          if (this.resendCounter == 0) {
            clearInterval(timer);
            this.enableSendAgain = true;
          }
        }, 1000);
      }
    },

    //showing loader for 10 seconds so the payment status will be changed to success for razorpay.
    decrementCounter(otp) {
      if (this.counter > 1) {
        let timer = setInterval(() => {
          this.counter--;
          if (this.counter < 0) {
            clearInterval(timer);
            this.checkPaymentStatus(otp);
          }
        }, 1000);
      }
    },

    fetchPaymentCallback(payload) {
      // return store.dispatch("OTPPaymentConfirmation", payload);
      return store.dispatch("telcoPaymentConfirmation", payload);
    },

    checkPaymentStatus(otp) {
      this.paymentInitiatedResponse.referencedata.otpverify = otp;
      this.paymentInitiatedResponse.referencedata.mobileno = `${this.country_code}${this.mobile_number}`;

      let payload = {
        gatewayid: this.selectedGateway.gatewayid,
        params: {
          referenceid: this.paymentInitiatedResponse.referenceid,
          referencedata: JSON.stringify(
            this.paymentInitiatedResponse.referencedata
          ),
        },
      };

      console.log("callback response payload", payload);

      this.fetchPaymentCallback(payload)
        .then((response) => {
          console.log("callback response", response);
          // if(response.otpConfirm == "SUCCESS"){
           
          // }
          if (!response.reason) {
            this.$router.push({
              name: "telcoPaymentHandler",
              params: {
                lang: this.localDisplayLang == "ara" ? "ar" : "en",
                referenceid: this.paymentInitiatedResponse.referenceid,
              },
              query: {
                response: JSON.stringify(response.otpConfirm), // Convert object to string
              },
            });
         } else {
            this.verifyNumber = true;
            this.snackbarMessage(response.reason);
            this.counter = 10;
         }
        })
        .catch((error) => console.log("error", error));
    },

    validateMobile(number) {
      if (!number) {
        return "Please Enter Mobile number";
      }

      if (this.isTpay && number && number.length < 10) {
        return "Mobile number should be minimum 10 digits";
      } else if (number && number.length < 6) {
        return "Mobile number should be minimum 6 digits";
      } else {
        return true;
      }
    },

    snackbarMessage(message) {
      // snackbar.show({
      //   text: this.$t(message),
      //   textColor: "#ffffff",
      //   pos: "bottom-center",
      //   actionTextColor: "#de2600",
      // });

      showSnackBar(this.$t(message));
    },

    redirect() {
      // this.$emit("redirect");
    },
  },
  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
  },
  mixins: [googleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.mobile-number {
  width: 100% !important;
  margin: 0.4rem 0;
  padding: 0.7rem;
  background: transparent;
  border: 1px solid #5c5c5d;
  border-radius: 0.1rem;
  caret-color: #5c5c5d;
  color: #efeff4;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  outline: none;
}

.card {
  background: #ffffff1f 0% 0% no-repeat padding-box;
  border: 1px solid #404040;
  border-radius: 14px;
  width: 362px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &_logo {
    text-align: center;
    .logo_oridinary {
      // width: 80%;
      height: 48px;
    }
    .logo_unique {
      width: 80%;
      height: 70px;
    }

    .logo_etisalat {
      height: 70px;
      width: auto;
      // max-width: 80%;
    }
  }
  &_welcomeText {
    margin: 10px;
    color: #ffffff;
    font-family: $font-helvetica-medium;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    &_primary_text {
      font-size: 1.2rem;
    }
    &_secondary_text {
      font-size: 0.6rem;
    }
  }
  &_verification_code_text {
    color: white;
    font-family: $font-helvetica-medium;
    font-weight: 200;
    font-size: 16px;
    margin-top: 15px;
    opacity: 0.8;
    line-height: 1.5rem;
  }
  &_error_message {
    font-size: 12px;
    color: red;
    margin-top: 10px;
    font-family: $font-helvetica-medium;
  }
  &_send_again {
    font-size: 12px;
    margin-top: 15px;
    font-family: $font-helvetica-medium;
    .enableSendAgain {
      color: red;
      cursor: pointer;
      text-decoration: underline;
    }
    .disableSendAgain {
      color: grey;
      cursor: not-allowed;
      text-decoration: underline;
    }
  }
  &_resend_counter {
    color: #ffffff;
    margin: 0 5px;
    line-height: 1.4rem;
  }
  &_otp_setup {
    margin-left: -17px;
    margin-top: 15px;

    &-ara {
      margin-left: 0;
    }
  }
  &_journey-otp {
    height: 40px;
    width: 40px;
    font-size: 22px;
    text-align: center;
    border-bottom-color: #ffffff;
    border-width: 0 0 1px;
    color: black;
    margin-left: 13px;
    border-radius: 7px;
  }
  &_mobile_input_body {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    // padding-left: 13px;
    // width: 80%;
  }
  &_input_country_code {
    border: 0px !important;
    border-bottom: 2px solid #aa1a62 !important;
    font-size: 16px !important;
    padding-right: 3px;
    height: 24px;
    opacity: 0.7;
    color: white;
    padding-top: 2px;
    letter-spacing: 3px !important;
    font-family: "Open Sans", sans-serif;
  }
  &_input_text {
    border: 0px !important;
    border-bottom: 2px solid #aa1a62 !important;
    padding: 0 !important;
    font-size: 16px !important;
    padding-left: 0 !important;
    height: 23px;
    opacity: 0.7;
    background-color: transparent !important;
    letter-spacing: 3px !important;
    width: 66%;
  }
  &_input_text_right {
    border: 0px !important;
    border-bottom: 2px solid #aa1a62 !important;
    padding: 0.7rem 0 !important;
    font-size: 16px !important;
    padding-left: 0 !important;
    height: 23px;
    opacity: 0.7;
    background-color: transparent !important;
    letter-spacing: 3px !important;
    width: 66%;
  }
  &_inputs {
    // margin: 10px;
    &_cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      padding: 5px 10px;
      margin-bottom: 10px;
      // width: 250px;
      cursor: pointer;
      &_pricetag {
        color: #ffffff;
        font-family: $font-helvetica-medium;
        font-weight: 400;
        font-size: 0.7rem;
      }
      &:hover {
        border: 1px solid #aa1a62;
        background: #aa1a622c;
      }
    }
  }
  &_parallel_text {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    p {
      color: white;
      font-family: "Open Sans", sans-serif;
      font-weight: 200;
      font-size: 15px;
      opacity: 0.7;
    }
  }
  &_button {
    text-align: center;
    button {
      background: transparent linear-gradient(180deg, #9d1153 0%, #cd154e 100%)
        0% 0% no-repeat padding-box;
      border-radius: 7px;
      border: none;
      padding: 10px 15px;
      color: #ffffff;
      cursor: pointer;
      font-family: $font-regular;
    }
  }
  &_button_verify {
    text-align: left;
    margin-top: 10px;
  }
  &_button_verify_right {
    text-align: right;
    margin-top: 10px;
  }
  &_info {
    margin-top: 20px;
    font-family: $font-helvetica-medium;
    font-weight: 400;
    font-size: 0.6rem;
    text-align: center;
    .primary {
      color: #b7b7b7;
      margin-bottom: 10px;
    }
    .click {
      cursor: pointer;
      color: #aa1a62;
    }
    .secondary {
      color: #b7b7b7;
      line-height: 0.8rem;
      //white-space: pre-line;
      text-align: center;
    }
  }
  &_paymentProgress {
    text-align: center;
    &_please_wait {
      color: #b8c0c7;
      font-family: $font-regular;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 36px;
      text-align: center;
    }
    &_payment_progress {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    &_loading_gif {
      width: 85px;
    }
  }
}

.active_input {
  border: 1px solid #aa1a62;
  background: #aa1a622c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* CSS classes for left alignment*/

/* Customize the label (the container) */
.radio_container {
  display: block;
  position: relative;
  padding-left: 25px;
  // margin-top: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio_label {
  color: #ffffff;
}

/* Hide the browser's default radio */
.radio_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio */
.radiomark_check {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.radio_container:hover input ~ .radiomark_check {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.radio_container input:checked ~ .radiomark_check {
  background-color: #aa1a62;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiomark_check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio_container input:checked ~ .radiomark_check:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio_container .radiomark_check:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* CSS classes for right alignment*/

/* Customize the label (the container) */
.radio_container_right {
  display: block;
  position: relative;
  padding-right: 25px;
  // margin-top: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.radio_container_right input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.radiomark_check_right {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.radio_container_right:hover input ~ .radiomark_check_right {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.radio_container_right input:checked ~ .radiomark_check_right {
  background-color: #aa1a62;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiomark_check_right:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio_container_right input:checked ~ .radiomark_check_right:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio_container_right .radiomark_check_right:after {
  right: 5px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 576px) {
  .card_journey-otp {
    margin: 0 3px;
    height: 35px;
    width: 30px;
  }

  .card_otp_setup {
    margin-left: 0 !important;
  }

  .card {
    width: 100%;
    padding: 25px;
    &_mobile_input_body {
      width: 100%;
    }
    &_input_country_code {
      height: 25px;
      padding-top: 3px;
    }
    &_input_text {
      padding: 0.7rem 0 !important;
    }
  }
}
</style>
